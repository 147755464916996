import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import EvaTooltip from '../../Components/EvaTooltip';
import MessageEmailPreview from '../../Components/MessageEmailPreview';
import MessageSmsPreview from '../../Components/MessageSmsPreview';
import MessageWebPreview from '../../Components/MessageWebPreview';
import MessageFacebookPreview from '../../Components/MessageFacebookPreview';
import MessageTwitterPreview from '../../Components/MessageTwitterPreview';
import MessageMyParishAppPreview from '../../Components/MessageMyParishAppPreview';
import {
  CONSTANTS,
  saveDraftMessage,
  selectPreviewImage,
  sendDraftTest,
  validateMessage,
  updateFacebookPreviewContent,
  updateMPAPreviewContent,
  updateSendByEmail,
  updateSendByFacebook,
  updateSendByMPA,
  updateSendBySms,
  updateSendByTwitter,
  updateSendByWeb,
  updateSmsPreviewContent,
  updateTwitterPreviewContent,
  updateWebPreviewContent,
} from '../../Actions/messageActions';
import { closeCustomModal } from '../../Actions/customModalActions';
import { enableRightDrawer } from '../../Actions/rightDrawerActions';
import LoaderOverlay from '../../Components/LoaderOverlay';
import { MessageActionsButtons } from '../../Components/MessageActionsButtons';
import { MESSAGE_DESIGNER_TYPE } from '../../Config/Constants';
import TemplateSettings from '../TemplateSettings';
import { getActiveClass } from '../../Helpers/getActiveClass';
import './index.sass';

const EMAIL_PREVIEW_TAB = 0;
const SMS_PREVIEW_TAB = 1;
const MY_PARISH_PREVIEW_TAB = 2;
const FACEBOOK_PREVIEW_TAB = 3;
const TWITTER_PREVIEW_TAB = 4;
const WEB_PREVIEW_TAB = 5;

const TabContainer = props => <Typography component="div">{props.children}</Typography>;

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  wrapper: {},
  tab: {
    color: theme.palette.background.paper,
  },
  tabsIndicator: {
    background: 'linear-gradient(to right, #9966bf 0%, #6174c9 98%)',
  },
  tabsRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: 'GothamSSm-Book',
    color: '#a9a9a9',
    width: '97%',

    '&:focus': {
      color: '#9a66bf',
    },
  },
  tabRoot: { minWidth: 'auto' },
  tabSelected: { color: '#9a66bf' },
});

class MessagePreview extends Component {
  state = {
    tabIndex: 0,
  };

  handleChange = (event, tabIndex) => this.setState({ tabIndex });

  componentDidMount() {
    const { allowedChannels: { email } } = this.props;
    this.setState({ tabIndex: email ? EMAIL_PREVIEW_TAB : SMS_PREVIEW_TAB });
  }

  handleSaveAsTemplate = () => {
    this.props.enableRightDrawer({
      payload: {
        enable: true,
        element: <TemplateSettings/>,
        menuOptions: null,
        title: 'Save as template',
        secondTitle: '',
        cleaner: null,
      },
    });
    this.props.closeCustomModal();
  }

  render() {
    const { tabIndex } = this.state;
    const {
      classes, showButtons, recipientType, isAllMemberGroupSelected,
      twitterProfile, facebookProfile, profilePic, profileName, subject, allowedChannels, contentImages,
      isTwitterLoading, isFacebookLoading, isLiteMode, isDirectMessage,
      sendByEmail, sendByFacebook, sendByMpa, sendBySms, sendByTwitter, sendByWeb,
      messageContent: {
        mainPicture, editorContent: { html }, facebookContent, mpaContent, smsContent, twitterContent, webContent,
      },
    } = this.props;

    return (
      <div className={`${classes.root} message-preview-container `}>
        <div className="tabs-buttons-container">
          <div className="preview-buttons-container">
            {showButtons && (
              <MessageActionsButtons
                isDraftSaveAllowed={recipientType === CONSTANTS.RECIPIENT_TYPE_GROUP}
                onSend={this.props.onSend}
                onDraftTestSend={this.props.onDraftTestSend}
                onDraftSave={() => this.props.saveDraftMessage(true)}
                onTemplateSave={this.handleSaveAsTemplate}
              />
            )}
          </div>

          <div className="tabs-container">
            <Tabs
              classes={{
                root: classes.tabsRoot,
                indicator: classes.tabsIndicator,
              }}
              value={tabIndex}
              onChange={this.handleChange}
            >
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Email">
                    <i className={`email-icon ${getActiveClass(allowedChannels.email && sendByEmail)}`} />
                  </EvaTooltip>
                }
                disabled={isDirectMessage && !allowedChannels.email}
              />
              <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Texting">
                    <i className={`sms-icon ${getActiveClass(allowedChannels.sms && sendBySms)}`} />
                  </EvaTooltip>
                }
                disabled={isDirectMessage && !allowedChannels.sms}
              />

              {!isDirectMessage && <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="myParish App">
                    <i className={`t-icon ${getActiveClass(allowedChannels.mpa && sendByMpa)}`} />
                  </EvaTooltip>
                }
              />}

              {!isDirectMessage && <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Facebook">
                    <i className={`facebook-icon ${getActiveClass(allowedChannels.facebook && sendByFacebook)}`} />
                  </EvaTooltip>
                }
              />}

              {!isDirectMessage && <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="X">
                    <i className={`x-icon ${getActiveClass(allowedChannels.twitter && sendByTwitter)}`} />
                  </EvaTooltip>
                }
              />}

              {!isDirectMessage && <Tab
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
                label=""
                icon={
                  <EvaTooltip title="Web">
                    <i className={`web-icon ${getActiveClass(allowedChannels.web && sendByWeb)}`} />
                  </EvaTooltip>
                }
              />}

            </Tabs>

          </div>
        </div>
        <div/>
        {tabIndex === EMAIL_PREVIEW_TAB && (
          <TabContainer>
            <MessageEmailPreview
              allowed={allowedChannels.email}
              isPreventChange={isDirectMessage && allowedChannels.email && sendByEmail && !allowedChannels.sms}
              content={html}
              enabled={sendByEmail}
              recipientType={recipientType}
              previewImages={contentImages}
              selectedPreviewImage={mainPicture}
              onSendByChange={this.props.updateSendByEmail}
              onPreviewChange={this.props.selectPreviewImage}
            />
          </TabContainer>
        )}
        {tabIndex === SMS_PREVIEW_TAB && (
          <TabContainer>

            <MessageSmsPreview
              allowed={allowedChannels.sms}
              isPreventChange={ isDirectMessage && allowedChannels.sms && sendBySms && !allowedChannels.email }
              isLiteMode={isLiteMode}
              enabled={sendBySms && !isLiteMode}
              content={smsContent.text}
              prefix={smsContent.prefix}
              recipientType={recipientType}
              onSendByChange={this.props.updateSendBySms}
              onChange={this.props.updateSmsPreviewContent}
            />
          </TabContainer>
        )}

        {!isDirectMessage && tabIndex === MY_PARISH_PREVIEW_TAB && (
          <TabContainer>
            <MessageMyParishAppPreview
              allowed={allowedChannels.mpa}
              toAllMembers={isAllMemberGroupSelected}
              enabled={sendByMpa}
              content={mpaContent.text}
              subject={subject}
              profilePic={profilePic}
              profileName={profileName}
              onSendByChange={this.props.updateSendByMPA}
              onChange={this.props.updateMPAPreviewContent}
            />
          </TabContainer>
        )}
        {!isDirectMessage && tabIndex === FACEBOOK_PREVIEW_TAB && (
          <TabContainer>
            {isFacebookLoading ? <LoaderOverlay/> : (
              <MessageFacebookPreview
                allowed={allowedChannels.facebook && !!facebookProfile}
                enabled={sendByFacebook}
                content={facebookContent.text}
                image={facebookContent.image}
                profile={facebookProfile}
                profilePic={facebookProfile ? facebookProfile.pagePicture : null}
                profileName={facebookProfile ? facebookProfile.pageName : null}
                previewImages={contentImages}
                selectedPreviewImage={mainPicture}
                onSendByChange={this.props.updateSendByFacebook}
                onChange={this.props.updateFacebookPreviewContent}
                onPreviewChange={this.props.selectPreviewImage}
              />
            )}
          </TabContainer>
        )}
        {!isDirectMessage && tabIndex === TWITTER_PREVIEW_TAB && (
          <TabContainer>
            {isTwitterLoading ? <LoaderOverlay/> : (
              <MessageTwitterPreview
                allowed={allowedChannels.twitter && !!twitterProfile}
                enabled={sendByTwitter}
                content={twitterContent.text}
                image={twitterContent.image}
                profile={twitterProfile}
                previewImages={contentImages}
                selectedPreviewImage={mainPicture}
                onSendByChange={this.props.updateSendByTwitter}
                onChange={this.props.updateTwitterPreviewContent}
                onPreviewChange={this.props.selectPreviewImage}
              />
            )}
          </TabContainer>
        )}
        {!isDirectMessage && tabIndex === WEB_PREVIEW_TAB && (
          <TabContainer>
            <MessageWebPreview
              allowed={allowedChannels.web}
              enabled={sendByWeb}
              content={webContent.text}
              previewImages={contentImages}
              selectedPreviewImage={mainPicture}
              onSendByChange={this.props.updateSendByWeb}
              onChange={this.props.updateWebPreviewContent}
              onPreviewChange={this.props.selectPreviewImage}
            />
          </TabContainer>
        )}
      </div>
    );
  }
}

const mS = state => ({
  allowedChannels: state.messageReducer.allowedChannels,
  buttons: state.composeReducer.buttons,
  contentImages: state.messageReducer.contentImages,
  isAllMemberGroupSelected: state.messageReducer.isAllMemberGroupSelected,
  isDirectMessage: state.messageReducer.recipientType === CONSTANTS.RECIPIENT_TYPE_MEMBER,
  isFacebookLoading: state.socialShareReducer.isFacebookLoading,
  isLiteMode: state.organizationReducer.organization.lite,
  isTwitterLoading: state.socialShareReducer.isTwitterLoading,
  facebookProfile: state.socialShareReducer.facebookProfile,
  messageContent: state.messageReducer.messageContent,
  profileName: state.userProfileReducer.display_name,
  profilePic: state.userProfileReducer.member.profile_picture_url,
  recipientType: state.messageReducer.recipientType,
  selectedMember: state.messageReducer.selectedMember,
  sendByEmail: state.messageReducer.sendByEmail,
  sendByFacebook: state.messageReducer.sendByFacebook,
  sendByMpa: state.messageReducer.sendByMpa,
  sendBySms: state.messageReducer.sendBySms,
  sendByTwitter: state.messageReducer.sendByTwitter,
  sendByWeb: state.messageReducer.sendByWeb,
  subject: state.messageReducer.subject,
  twitterProfile: state.socialShareReducer.twitterProfile
});

const mD = {
  enableRightDrawer,
  closeCustomModal,
  saveDraftMessage,
  selectPreviewImage,
  sendDraftTest,
  validateMessage,
  updateSendBySms,
  updateSendByEmail,
  updateSendByMPA,
  updateSendByTwitter,
  updateSendByWeb,
  updateSmsPreviewContent,
  updateMPAPreviewContent,
  updateTwitterPreviewContent,
  updateWebPreviewContent,
  updateSendByFacebook,
  updateFacebookPreviewContent,
};

export default withStyles(styles)(
  connect(
    mS,
    mD,
  )(MessagePreview),
);
